<app-modal-header
    [title]="dialogData.title"
    [dialogRef]="dialogRef">
</app-modal-header>
<app-modal-content>

    <div class="row">
        <div *ngIf="dialogData.icon" class="col-12 text-center mb-3">
            <mat-icon class="confirm-icon">
                {{dialogData.icon}}
            </mat-icon>
        </div>

        <div class="col-12 confirm-messages">
            <div *ngFor="let message of dialogData.messages"
                 [innerHtml]="message.key | translate:getMessageArgs(message)">
            </div>
        </div>
    </div>

</app-modal-content>
<app-modal-footer
    [dialogRef]="dialogRef"
    [hideSecondaryButton]="true"
    [primaryBtnIcon]="''"
    [primaryBtnTranslateKey]="'Common.Confirm' | translate"
    (primaryBtnClicked)="closeModal()">
</app-modal-footer>
