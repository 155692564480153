<app-modal-header
    [title]="dialogData.title | translate"
    [dialogRef]="dialogRef"
></app-modal-header>

<app-modal-content>
    <div class="row">
        <div class="col-12 delete-modal-content">
            {{'Questions.AreYouSureDelete' | translate: {deleteItemName: deleteItemName} }}
        </div>
    </div>
</app-modal-content>

<app-modal-footer
    [dialogRef]="dialogRef"
    [primaryBtnTranslateKey]="'Common.Yes'"
    [primaryBtnIcon]="'check'"
    (primaryBtnClicked)="delete()"
    [secondaryBtnTranslateKey]="'Common.Cancel'"
    [secondaryBtnIcon]="'close'">
</app-modal-footer>
