import { Component, Injector } from '@angular/core';
import { MatModalBaseComponent } from '../../mat-modal-base.component';
import { ConfirmModalData, ConfirmModalDataMessage } from './classes/confirm-modal-data';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent extends MatModalBaseComponent<ConfirmModalData> {
  constructor(protected injector: Injector) {
    super(injector);
  }

  getMessageArgs(message: ConfirmModalDataMessage): object {
    return message.args ?? {};
  }
}
