import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatModalBaseComponent } from '../mat-modal-base.component';

@Component({
  selector: 'app-modal-footer',
  templateUrl: './modal-footer.component.html',
  styleUrls: ['./modal-footer.component.scss'],
})
export class ModalFooterComponent {
  @Input() dialogRef: MatDialogRef<MatModalBaseComponent>;

  @Input() primaryBtnTranslateKey = 'Common.Save';
  @Input() primaryBtnIcon = 'fas fa-check';
  @Input() primaryBtnDisabled: boolean;
  @Input() hidePrimaryBtn: boolean;

  @Input() secondaryBtnTranslateKey = 'Common.Cancel';
  @Input() secondaryBtnIcon = 'fas fa-check';
  @Input() secondaryBtnDisabled: boolean;
  @Input() hideSecondaryButton: boolean;

  @Output() primaryBtnClicked = new EventEmitter();
  @Output() secondaryBtnClicked = new EventEmitter();

  onPrimaryClicked() {
    if (this.primaryBtnClicked) {
      this.primaryBtnClicked.emit();
    }
  }

  onSecondaryClicked() {
    if (this.secondaryBtnClicked) {
      this.secondaryBtnClicked.emit();
    }

    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}
