<mat-dialog-actions class="modal-footer d-flex justify-content-end">

    <app-button
        *ngIf="!hideSecondaryButton"
        class="modal-footer-secondary-button me-3"
        [disabled]="secondaryBtnDisabled"
        [text]="secondaryBtnTranslateKey | translate"
        (click)="onSecondaryClicked()"
        [icon]="secondaryBtnIcon">
    </app-button>

    <app-button
        *ngIf="!hidePrimaryBtn"
        color="primary"
        class="modal-footer-primary-button"
        [disabled]="primaryBtnDisabled"
        (click)="onPrimaryClicked()"
        [icon]="primaryBtnIcon"
        [text]="primaryBtnTranslateKey | translate">
    </app-button>

</mat-dialog-actions>

