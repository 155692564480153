<app-modal-header
    [title]="'Modal example title'"
    [dialogRef]="dialogRef"
></app-modal-header>
<app-modal-content>
    <p>This is a modal example!</p>
</app-modal-content>
<app-modal-footer
    [primaryBtnIcon]="'check'"
    (primaryBtnClicked)="onPrimaryButtonClicked()"
    [secondaryBtnIcon]="'close'"
    (secondaryBtnClicked)="onSecondaryButtonClicked()">
</app-modal-footer>
