import { Component, EventEmitter, Injector, Output } from '@angular/core';
import { MatModalBaseComponent } from '../../mat-modal-base.component';
import { DeleteModalData } from './classes/delete-modal-data';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss'],
})
export class DeleteModalComponent<T> extends MatModalBaseComponent<DeleteModalData<T>, T> {
  @Output() deleted = new EventEmitter();

  deleteItem: T;
  deleteItemName: string;

  constructor(protected injector: Injector) {
    super(injector);

    this.deleteItem = this.dialogData.deleteItem;
    this.deleteItemName = this.dialogData.deleteItemName;
  }

  delete() {
    this.closeModal(this.deleteItem);
  }
}
