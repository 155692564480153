import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalContentComponent } from './modal-content/modal-content.component';
import { DeleteModalComponent } from './modals/delete-modal/delete-modal.component';
import { PrivacyPolicyModalComponent } from './modals/privacy-policy-modal/privacy-policy-modal.component';
import { ModalFooterComponent } from './modal-footer/modal-footer.component';
import { ModalHeaderComponent } from './modal-header/modal-header.component';
import { MatModalBaseComponent } from './mat-modal-base.component';
import { MatModalExampleComponent } from './modals/mat-modal-example/mat-modal-example.component';
import { MatModalService } from './mat-modal.service';
import { MatDialogModule } from '@angular/material/dialog';
import { FormComponentsModule } from '../form-components/form-components.module';
import { SharedModule } from '../../shared.module';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';

@NgModule({
  declarations: [
    MatModalBaseComponent,
    MatModalExampleComponent,
    ModalHeaderComponent,
    ModalFooterComponent,
    ModalContentComponent,
    DeleteModalComponent,
    PrivacyPolicyModalComponent,
    ConfirmModalComponent,
  ],
  exports: [MatModalBaseComponent, ModalHeaderComponent, ModalFooterComponent, ModalContentComponent],
  providers: [MatModalService],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    TranslateModule,
    MatIconModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatSelectModule,
    FormComponentsModule,
    SharedModule,
  ],
})
export class MatModalModule {}
