import { Component } from '@angular/core';
import { MatModalBaseComponent } from '../../mat-modal-base.component';

@Component({
  selector: 'app-mat-modal-example',
  templateUrl: './mat-modal-example.component.html',
  styleUrls: ['./mat-modal-example.component.scss'],
})
export class MatModalExampleComponent extends MatModalBaseComponent<void> {
  static modalConfig = {
    closeOnNavigation: true,
  };

  onPrimaryButtonClicked() {
    console.log('onPrimaryButtonClicked');
    this.dialogRef.close();
  }

  onSecondaryButtonClicked() {
    console.log('onSecondaryButtonClicked');
    this.dialogRef.close();
  }
}
